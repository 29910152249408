.footer {
  display: flex;

  height: 96px;
  padding: 0 26px 0 26px;

  background: #FFFFFF;
  box-shadow: 0px 0px 46px -16px rgba(21, 70, 160, 0.1);
  border-radius: 40px 0px 0px 0px;

  button {
    flex: 1;
    border: none;
    background: #FFFFFF;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #0070BA;

    &:nth-child(1) {
      flex-grow: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      img, span {
        text-align: left;
      }
      span {
        margin-left: 11px;
      }
    }
  }
}