.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  width: 100%;
  height: 272px;

  /* Gradient */

  background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #0070BA 0%, #1546A0 100%);
  /* blue */

  box-shadow: 0px 16px 36px -16px rgba(21, 70, 160, 0.5);
  border-radius: 0px 0px 41px 0px;

  font-family: Manrope;
  font-style: normal;
  color: #FFFFFF;

  .fill8 {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.15;
  }

  .title {
    position: absolute;
    left: 32px;
    top: 85px;
    font-weight: normal;
    font-size: 24px;
    opacity: 0.5;
  }

  .balance {
    position: absolute;
    left: 32px;
    top: 165px;
    font-weight: 200;
    font-size: 40px;
  }

  .balanceTitle {
    position: absolute;
    left: 32px;
    top: 222px;
    font-weight: normal;
    font-size: 16px;
  }
}

.main {
  flex: 1;

  .buttonsBar {
    margin-top: 24px;
    padding: 0 26px 0 26px;

    display: flex;

    button {
      position: relative;
      height: 120px;
      width: 107px;
      margin: 0 8px 0 8px;

      border: none;

      span {
        position: absolute;
        bottom: 17px;
        left: 20px;

        text-align: left;

        font-family: Manrope;
        font-style: normal;
        font-weight: 200;
        font-size: 12px;
        line-height: 16px;

        /* White */

        color: #FFFFFF;
      }
      &:nth-child(1) {
        background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #0070BA 0%, #1546A0 100%);
        box-shadow: 0px 24px 48px -18px rgba(21, 70, 160, 0.5);
        border-radius: 20px;

        img {
          position: absolute;
          left: 22px;
          top: 22px;
        }
      }
      &:nth-child(2) {
        background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #0070BA 0%, #1546A0 100%);
        box-shadow: 0px 24px 48px -18px rgba(21, 70, 160, 0.5);
        border-radius: 20px;

        img {
          position: absolute;
          left: 22px;
          top: 22px;
        }
      }

      &:nth-child(3) {
        background: #FFFFFF;
        box-shadow: 2px 8px 48px -16px rgba(21, 70, 160, 0.1);
        border-radius: 20px;

        img {
          position: absolute;
          left: 22px;
          top: 22px;
        }

        span {
          color: #005EA6;
        }
      }

      &:nth-child(4) {
        width: 66px;
        background: #FFFFFF;
        box-shadow: 2px 8px 48px -16px rgba(21, 70, 160, 0.1);
        border-radius: 20px;
      }
    }
  }

  .view {
    margin-top: 30px;
    padding: 0 26px 0 26px;

    .viewBar {
      display: flex;
      justify-content: space-between;

      .mainTitle {
        font-family: Manrope;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        color: #243656;
      }

      button.seeAllBtn {
        border: none;
        background: #FFFFFF;

        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        color: #243656;
        opacity: 0.5;
      }
    }
  }
}