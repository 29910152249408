.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  padding: 26px;

  button {
    border: none;
    background: #FFFFFF;
  }

  div {
    flex: 1;
    text-align: center;
  };
}

.ticket {
  flex: 1;
  position: center;
  img {
    width: 100%;
  }

  .ticketHeader {
    padding: 30px 0;
    border-bottom: 1px solid lightgray;
    text-align: center;

    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    color: #000000;

    .address {
      font-size: 16px;
    }

    .date, .no {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-top: 5px;
    }
    .date {
      margin-top: 14px;
    }
  }

  .ticketItemsContainer {
    width: 100%;
    padding: 30px 0;

    border-bottom: 1px solid lightgray;

    .ticketItem {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding: 5px 0;

      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 30px;
      color: #000000;

      .ticketItemPrice {
        font-weight: normal;
      }
    }
  }

  .ticketFooterContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 31px;

    .ticketFooter {
      width: 60%;

      .ticketFooterLine {
        display: flex;
        justify-content: space-between;

        padding: 5px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        text-align: justify;
        letter-spacing: 1px;
        color: #000000;

        div {
          display: inline-block;
        }
      }

      .totalTtc {
        font-weight: bold;
        border-top: 1px solid lightgray;
      }
    }
  }
}