.list {
  .item {
    margin-bottom: 8px;
    padding: 16px 12px;

    box-shadow: 2px 8px 48px -16px rgba(21, 70, 160, 0.1);
    border-radius: 20px;

    display: flex;
    justify-content: space-evenly;

    .col {
      display: flex;
      align-items: center;
      justify-content:  flex-end;
    }

    i.icon {
      width: 32px;
      height: 32px;
      background: #F5F7FA;
      border-radius: 20px;

      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 32px;
      align-items: center;
      text-align: center;
      color: #243656;
    }

    .label {
      flex: 1;
      padding: 0 12px;

      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #243656;
    }

    .date {
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #243656;
      opacity: 0.5;
    }

    img.ticket {
      width: 21px;
      height: 28px;
    }

    .price {
      width: 50px;

      font-family: Manrope;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      /* identical to box height */

      text-align: right;

      color: #000000;
    }
  }

}